/* @flow */

import React, { useState } from "react";

import { useSendMessage } from "crustate/react";
import { useTranslate } from "@crossroads/use-translate";
import { Input, Checkbox } from "@crossroads/ui-components";
import Button from "components/Button";
import { Box, BoxHeader, BoxBody } from "components/Box";
import { setQuoteDiscountCode, removeQuoteDiscountCode } from "state/quote";

import styles from "./styles.scss";

type Props = {
  coupon?: {
    code: string,
    label: string,
  },
  setSummaryOpen: boolean => void,
  disabled?: boolean,
};

const DiscountCode = ({ coupon = { code: "", label: "" }, setSummaryOpen, disabled }: Props): React$Node => {
  const t = useTranslate();
  const sendMessage = useSendMessage();
  const [discountCode, setDiscountCode] = useState<string>(coupon ? coupon.code : "");
  const [open, setOpen] = useState<boolean>(coupon ? Boolean(coupon.code) : false);

  const removeCode = () => {
    sendMessage(removeQuoteDiscountCode());
    setDiscountCode("");
    setSummaryOpen(true);
    setOpen(false);
  };

  const onSubmit = (e?: Event) => {
    if (e) {
      e.preventDefault();
    }

    if (discountCode.length === 0 || disabled === true) {
      return;
    }

    sendMessage(setQuoteDiscountCode(discountCode));
    setSummaryOpen(true);
  };

  const onRemove = (e?: Event) => {
    if (e) {
      e.preventDefault();
    }

    if (disabled === true) {
      return;
    }

    removeCode();
  };

  return (
    <Box className={styles.block}>
      <BoxHeader className={styles.boxHeader}>
        <div>
          <Checkbox
            type="checkbox"
            name="checkDiscountCode"
            value={open}
            onChange={() => setOpen(!open)}
          >
            {t("CHECKOUT.DISCOUNT_CODE.CHECKBOX_LABEL")}
          </Checkbox>
        </div>
      </BoxHeader>
      {open &&
      <BoxBody>
        <div className={styles.discountCode}>
          <Input
            label={t("CHECKOUT.DISCOUNT_CODE.HEADER")}
            name="discountCode"
            disabled={coupon || disabled}
            value={discountCode}
            onChange={(e: SyntheticEvent<HTMLInputElement>) => {
              setDiscountCode(e.currentTarget.value);
            }}
            onKeyUp={(e: SyntheticKeyboardEvent<HTMLInputElement>) => {
              if (e.key === "Enter") {
                onSubmit();
              }
            }}
          />
          <Button
            className={styles.button}
            variant="primary"
            onClick={coupon ? onRemove : onSubmit}
          >
            {coupon ? t("CHECKOUT.DISCOUNT_CODE.REMOVE") : t("CHECKOUT.DISCOUNT_CODE.APPLY")}
          </Button>
        </div>
      </BoxBody>}
    </Box>
  );
};

export default DiscountCode;
