/* @flow */

import type { Storage } from "crustate";
import type { Client } from "@awardit/graphql-ast-client";
import type { TermsRequest, TermsResponse } from "state/terms";

import { TERMS_REQUEST, TERMS_RESPONSE } from "state/terms";
import { terms } from "queries";

const registerClient = (storage: Storage, client: Client<{}>) => {
  storage.addEffect({
    effect: async (msg: TermsRequest) => {
      const { terms: { cmsPage } } = await client(terms, {
        url: msg.url,
      }, { cache: true });

      return ({
        tag: TERMS_RESPONSE,
        data: cmsPage,
      }: TermsResponse);
    },
    subscribe: { [TERMS_REQUEST]: true },
  });
};

export default registerClient;
