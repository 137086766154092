/* @flow */

import React from "react";
import { Link } from "react-router-dom";
import Wrapper from "components/Wrapper";
import cn from "classnames";
import { useTranslate } from "@crossroads/use-translate";

import styles from "./styles.scss";

type Props = {
  className: string,
};

const AppFooter = ({ className = "" }: Props): React$Node => {
  const t = useTranslate();

  const links = () => (
    <section className={styles.links}>
      <p className={styles.title}>{t("FOOTER.LINKS.TITLE")}</p>
      <nav className={styles.nav}>
        <Link className={styles.link} to="/privacy-policy">
          {t("FOOTER.LINKS.PRIVACY")}
        </Link>
        <Link className={styles.link} to="/terms">
          {t("FOOTER.LINKS.TERMS")}
        </Link>
        <Link className={styles.link} to="/kundservice">
          {t("FOOTER.LINKS.FAQ")}
        </Link>
      </nav>
    </section>
  );

  const needHelp = () => (
    <section className={styles.needHelp}>
      <p className={styles.title}>{t("FOOTER.NEED_HELP.TITLE")}</p>

      <nav className={styles.nav}>
        <Link className={styles.link} to="/kundservice">{t("FOOTER.NEED_HELP.CONTACT")}</Link>
      </nav>
    </section>
  );

  const copyright = () => (
    <div className={styles.copyright}>
      <Wrapper>
        <section>
          <p dangerouslySetInnerHTML={{ __html: t("FOOTER.COPYRIGHT") }} />
        </section>
      </Wrapper>
    </div>
  );

  return (
    <footer className={cn(styles.block, className)}>
      <div className={styles.large}>
        <Wrapper className={styles.wrapper}>
          <div className={styles.col}>
            {needHelp()}
          </div>
          <div className={styles.col}>
            {links()}
          </div>
        </Wrapper>

        {copyright()}
      </div>

      <div className={styles.small}>
        <Wrapper className={styles.wrapper}>
          <div className={styles.col}>
            {needHelp()}
          </div>
          <div className={styles.col}>
            {links()}
          </div>
        </Wrapper>

        {copyright()}
      </div>
    </footer>
  );
};

export default AppFooter;
