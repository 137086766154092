/* @flow */

import type { RouterHistory as History, Location } from "react-router";

import React from "react";
import { Route, Switch } from "react-router";
import Redirect from "@crossroads/react-router-redirect";
import CmsPageView from "components/CmsPageView";
import {
  RouteData, OrderData, HomeData, BrandData, SearchData,
  TermsData, PopularData, AllProductsData, AdventCalendarData,
} from "data";
import { useData } from "crustate/react";
import App from "components/App";
import LoadingView from "components/LoadingView";
import CategoryView, { HintCategoryView } from "components/CategoryView";
import BrandView from "components/BrandView";
import PopularView from "components/PopularView";
import SearchView from "components/SearchView";
import AllProductsView from "components/AllProductsView";
import ProductView, { HintProductView } from "components/ProductView";
import AdventCalendarView from "components/AdventCalendarView";
import HomeView from "components/HomeView";
import SuccessView from "components/SuccessView";
import CheckoutView from "components/CheckoutView";
import NotFoundView from "components/NotFoundView";
import { parseParams } from "helpers/location-search-string";
import { locationWithDefaults } from "helpers/use-update-product-list";

type RoutesProps = {
  location: Location,
  history: History,
};

type RouteViewProps = {
  location: Location,
  history: History,
};

const RouteView = ({ location, history }: RouteViewProps): React$Node => {
  const data = useData(RouteData);

  if (data.state === "LOADING") {
    if (data.hint) {
      switch (data.hint.type) {
        case "product":
          return <HintProductView product={data.hint.product} />;
        case "category":
          return <HintCategoryView category={data.hint.category} />;
        case "cms_page":
          return <CmsPageView cmsPage={data.hint.cmsPage} />;
        default:
      }
    }

    return <LoadingView />;
  }

  if (!data.route) {
    // TODO: Check error msg
    return <NotFoundView />;
  }

  switch (data.route.type) {
    case "product":
      return <ProductView product={data.route.product} />;
    case "category":
      return <CategoryView category={data.route.category} location={location} history={history} />;
    case "cms_page":
      return <CmsPageView cmsPage={data.route.cmsPage} />;
    case "redirect":
      return <Redirect to={data.route.url} />;
    default:
      return null;
  }
};

const Routes = ({ location, history }: RoutesProps): React$Node => {
  return (
    <Switch>
      <App location={location} history={history}>
        <Switch>
          <Route
            exact path="/" render={() => (
              <HomeData.Provider>
                <HomeView />
              </HomeData.Provider>
            )} />

          <Route
            path="/checkout/success"
            render={() => (
              <OrderData.Provider>
                <SuccessView />
              </OrderData.Provider>
            )} />

          <Route
            exact
            path="/checkout/:step?"
            render={() => (
              <TermsData.Provider url="terms">
                <CheckoutView />
              </TermsData.Provider>
            )} />

          <Route
            path="/brand/:brand"
            render={({ match, location: { search, state } }) => {
              const { page } = parseParams(search);
              const brand = match.params.brand || "";

              return (
                <BrandData.Provider
                  location={locationWithDefaults(location)}
                  name={brand}
                  brand={decodeURIComponent(brand)}
                  page={page}
                >
                  <BrandView hint={state && state.hint} />
                </BrandData.Provider>
              );
            }} />

          <Route
            exact path="/popular-products"
            render={({ location: { search } }) => {
              const { page } = parseParams(search);

              return (
                <HomeData.Provider>
                  <PopularData.Provider
                    location={locationWithDefaults(location)}
                    page={page}
                  >
                    <PopularView />
                  </PopularData.Provider>
                </HomeData.Provider>
              );
            }} />

          <Route
            exact path="/all-products"
            render={({ location: { search } }) => {
              const { page } = parseParams(search);

              return (
                <AllProductsData.Provider
                  location={locationWithDefaults(location)}
                  page={page}
                >
                  <AllProductsView />
                </AllProductsData.Provider>
              );
            }} />

          <Route
            exact
            path="/julkalender"
            render={({ location: { search } }) => {
              const { password, testDate } = parseParams(search);
              return (
                <AdventCalendarData.Provider
                  testDate={typeof testDate === "string" ? testDate : null}
                  password={typeof password === "string" ? password : null}
                >
                  <AdventCalendarView />
                </AdventCalendarData.Provider>
              );
            }} />

          <Route
            path="/search/:query"
            render={({ match, location: { search } }) => {
              const { page } = parseParams(search);

              const query = decodeURIComponent(match.params.query || "");

              return (
                <SearchData.Provider
                  location={locationWithDefaults(location)}
                  page={page}
                  query={query}
                  name={`search_${query}`}
                >
                  <SearchView query={query} />
                </SearchData.Provider>
              );
            }}
          />

          <Route
            path="/(.+)"
            render={({ location: { pathname, search, state }, history }) => {
              const { page } = parseParams(search);

              return (
                <RouteData.Provider
                  location={locationWithDefaults(location)}
                  url={pathname}
                  name={`route_${pathname}`}
                  page={page}
                  hint={state && state.hint}
                >
                  <RouteView location={location} history={history} />
                </RouteData.Provider>
              );
            }} />
        </Switch>
      </App>
    </Switch>
  );
};

const AppRoutes = (): React$Node => <Route component={Routes} />;

export default AppRoutes;
