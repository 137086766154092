/* @flow */

import type { SerialCode as SerialCodeType } from "shop-state/types";

import React from "react";
import styles from "./styles.scss";
import Spinner from "components/Spinner";
import { Button } from "@crossroads/ui-components";
import { addMessage } from "@crossroads/shop-state/messages";
import { useTranslate } from "@crossroads/use-translate";
import { useSendMessage } from "crustate/react";
import useFormat from "helpers/use-format";
import CopyIcon from "icons/copy.svg";
import ErrorIcon from "icons/warning.svg";

type Props = {
  serialCode: ?SerialCodeType,
  fetchError: boolean,
};

const SerialCode = ({ serialCode, fetchError }: Props): React$Node => {
  const t = useTranslate();
  const { formatDate } = useFormat();
  const sendMessage = useSendMessage();

  const copyToClipboard = (code: string) => {
    const range = document.createRange();

    const node = document.getElementById("item__" + code);

    if (!node) {
      return;
    }

    range.selectNode(node);
    window.getSelection().addRange(range);

    try {
      document.execCommand("copy");
      sendMessage(addMessage("CODE_COPIED", "success"));
    }
    catch {
      sendMessage(addMessage("CODE_NOT_COPIED", "error"));
    }

    window.getSelection().removeAllRanges();
  };

  if (serialCode) {
    return (
      <div className={styles.itemBottom}>
        <div>
          <span id={"item__" + serialCode.code} className={styles.itemCode}>{serialCode.code}</span>
          {serialCode.expiryDate &&
            <p className={styles.itemExpiry}>
              {t("SUCCESS.VALID_UNTIL", { date: formatDate(serialCode.expiryDate) })}
            </p>
          }
        </div>
        <div>
          <div>
            <Button onClick={() => copyToClipboard(serialCode.code)}>
              <CopyIcon />
            </Button>
          </div>
        </div>
      </div>
    );
  }

  if (!serialCode && !fetchError) {
    return (
      <div className={styles.itemBottom}>
        <div className={styles.itemInfo}>{t("SERIAL_CODE.FETCH_STATUS.LOADING")}</div>
        <div>
          <Spinner />
        </div>
      </div>
    );
  }

  return (
    <div className={styles.itemBottom}>
      <div className={styles.itemInfo}>{t("SERIAL_CODE.FETCH_STATUS.ERROR")}</div>
      <div>
        <div>
          <ErrorIcon className={styles.errorIcon} />
        </div>
      </div>
    </div>
  );
};

export default SerialCode;
