/* @flow */

import type { OrderAddress, SerialCode as TSerialCode, OrderItem } from "shop-state/types";

import React, { useEffect, useState, useContext, memo, useRef } from "react";
import cn from "classnames";
import { AnalyticsContext } from "@crossroads/analytics";
import { useClient } from "entrypoint/shared";
import { useTranslate } from "@crossroads/use-translate";
import { Link } from "react-router-dom";
import Wrapper from "components/Wrapper";
import SerialCode from "./serial-code";
import usePrevious from "helpers/use-previous";
import { OrderData } from "data";
import { useData, useSendMessage } from "crustate/react";
import { lastOrder } from "queries";
import { syncOrder } from "state/order";
import LoadingView from "components/LoadingView";
import NotFoundView from "components/NotFoundView";
import CheckIcon from "icons/checkmark-success.svg";

import styles from "./styles.scss";

const POLL_INTERVAL = 3000;
const MAX_POLL = 8;

const formatAddress = (a: OrderAddress) => `${a.firstname} ${a.lastname}, ${a.street[0]}, ${a.postcode} ${a.city}`;

const renderSerialCodes = (
  sku: string,
  serialCodes: Array<TSerialCode>,
  qty: number,
  error: boolean) => {
  const components = [];

  for (let i = 0; i < qty; i++) {
    components.push(
      <SerialCode
        key={`${sku}_${i}`}
        serialCode={i < serialCodes.length ? serialCodes[i] : null}
        fetchError={error}
      />
    );
  }

  return components;
};

const itemCodesFetched = (x: OrderItem) => {
  return x.serialcodes === null || (Array.isArray(x.serialcodes) && x.serialcodes.length > 0);
};

const useFetchCodes = (): {|
  codesFetched: ?Array<boolean>,
  error: boolean,
|} => {
  const client = useClient();
  const sendMessage = useSendMessage();
  const orderData = useData(OrderData);
  const [codesFetched, setCodesFetched] = useState<?Array<boolean>>();
  const [fetchError, setFetchError] = useState(false);
  const fetchOrderCount = useRef(0);

  const fetchOrder = async () => {
    const response = await client(lastOrder);

    if (response.lastOrder) {
      sendMessage(syncOrder(response.lastOrder));
    }
    else if (orderData.state === "LOADED") {
      sendMessage(syncOrder(orderData.data));
    }
  };

  useEffect(() => {
    if (orderData.state === "LOADED" && orderData.data) {
      const _codesFetched = orderData.data.items.map(itemCodesFetched);
      setCodesFetched(_codesFetched);

      if (!_codesFetched.every(x => x) && fetchOrderCount.current < MAX_POLL) {
        const timeout = setTimeout(() => {
          fetchOrderCount.current += 1;
          fetchOrder();
        }, POLL_INTERVAL);

        return () => {
          clearTimeout(timeout);
        };
      }

      if (fetchOrderCount.current >= MAX_POLL) {
        setFetchError(true);
      }
    }
  }, [orderData, setCodesFetched]);

  return {
    codesFetched,
    error: fetchError,
  };
};

const SuccessView: React$AbstractComponent<any, any> = memo((): React$Node => {
  const t = useTranslate();
  const gaContext = useContext(AnalyticsContext);
  const orderData = useData(OrderData);
  const previousOrderState: string = usePrevious(orderData.state);
  const fetchCodes = useFetchCodes();

  useEffect(() => {
    if (orderData.state === "LOADED" && previousOrderState !== orderData.state && orderData.data) {
      gaContext.registerCheckoutSuccess(
        orderData.data,
        orderData.data.grandTotal?.incVat || 0
      );
    }
  }, [orderData, gaContext]);

  if (orderData.state !== "LOADED") {
    return <LoadingView />;
  }

  if (!orderData.data) {
    return (
      <Wrapper>
        <NotFoundView />
      </Wrapper>
    );
  }

  const order = orderData.data;
  const { id, items, addresses, email } = order;

  const shippingAddress = addresses.find(x => x.type === "shipping");
  const isVirtual = order.items.every(x =>
    (x.product.type === "virtual" || (x.configOption && x.configOption.product.type === "virtual")));
  const isMixed = order.items.some(x =>
    (x.product.type === "virtual" || (x.configOption && x.configOption.product.type === "virtual")));

  const KEY = isVirtual ? "VIRTUAL" : (isMixed ? "MIXED" : "NORMAL");

  return (
    <Wrapper>
      <header className={styles.header}>
        <div className={styles.mainTitle}>
          <div className={styles.checkIconBG}>
            <CheckIcon className={styles.checkIcon} />
          </div>
          <div>
            <h1 className={styles.title}>
              {t("SUCCESS.TITLE")}
            </h1>
            <h2 className={styles.orderNo}>
              {t("SUCCESS.SUB_TITLE", { number: id })}
            </h2>
          </div>
        </div>
        <div>
          <Link className={styles.back} to="/">{t("SUCCESS.BACK")}</Link>
        </div>
      </header>

      <div className={styles.block}>
        <div className={styles.right}>
          <h2 className={styles.subHeading}>{t("SUCCESS.WHAT_NOW")}</h2>
          <ol className={styles.steps}>
            <li>
              <h3>1. {t(`SUCCESS.STEPS.${KEY}.1.HEADING`)}</h3>
              <p>{t(`SUCCESS.STEPS.${KEY}.1.TEXT`, { email })}</p>
            </li>

            <li>
              <h3>2. {t(`SUCCESS.STEPS.${KEY}.2.HEADING`)}</h3>
              <p>{t(`SUCCESS.STEPS.${KEY}.2.TEXT`, { email })}</p>
            </li>

            {!isVirtual &&
              <li>
                <h3>3. {t(`SUCCESS.STEPS.${KEY}.3.HEADING`)}</h3>
                <p>{t(`SUCCESS.STEPS.${KEY}.3.TEXT`, { email })}</p>
              </li>
            }

            {!isVirtual &&
              <li>
                <h3>4. {t(`SUCCESS.STEPS.${KEY}.4.HEADING`)}</h3>
                <p>{t(`SUCCESS.STEPS.${KEY}.4.TEXT`, { email })}</p>
              </li>
            }
          </ol>
        </div>

        <div className={styles.left}>
          <h2 className={styles.subHeading}>{t("SUCCESS.ORDER_OVERVIEW")}</h2>
          <div className={styles.items}>
            {items.map(x => {
              const product = x.configOption ? {
                ...x.product,
                ...x.configOption.product,
              } : x.product;

              return (
                <div key={product.sku} className={styles.item}>
                  <div className={styles.itemTop}>
                    <img
                      className={styles.image}
                      src={product.attributes.image?.x1}
                      alt={product.name}
                    />

                    <div className={styles.info}>
                      <div>
                        <p className={styles.name}>{product.name}</p>
                        <p className={styles.brand}>{product.attributes.manufacturer}</p>
                      </div>
                    </div>
                  </div>
                  {Array.isArray(x.serialcodes) &&
                    renderSerialCodes(x.product.sku, x.serialcodes, x.qty, fetchCodes.error)
                  }
                </div>
              );
            })}
          </div>

          {shippingAddress && !isVirtual &&
            <section className={cn(styles.mapContainer, styles.mapContainer__large)}>
              <h2 className={styles.subHeading}>{t(`SUCCESS.STEPS.${KEY}.4.HEADING`)}</h2>
              <p className={styles.mapText}>{formatAddress(shippingAddress)}</p>
            </section>
          }
        </div>
      </div>
    </Wrapper>
  );
});

export default SuccessView;
