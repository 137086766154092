/* @flow */

import React, { useState, useEffect, useContext } from "react";
import cn from "classnames";
import { Helmet } from "react-helmet-async";
import { Route, Switch, useHistory } from "react-router";
import { AnalyticsContext } from "@crossroads/analytics";
import { useTranslate } from "@crossroads/use-translate";
import { StoreInfoContext, useClient } from "entrypoint/shared";
import { confirmStripePaymentIntent, createStripePaymentIntent } from "queries";
import { setQuotePaymentMethodStripe } from "@crossroads/shop-state/quote";
import { getQuoteData } from "state/quote";
import { useData, useSendMessage } from "crustate/react";
import { QuoteData } from "data";
import { StripePaymentProvider, STRIPE_PAYMENT_METHOD_CODE } from "@crossroads/stripe";
import Wrapper from "components/Wrapper";
import Header from "components/AppHeader/StepHeader/checkout-header";
import { Button } from "@crossroads/ui-components";
import useCheckoutSteps from "helpers/use-checkout-steps";
import ArrowIcon from "icons/arrow.svg";

import Cart from "components/CheckoutView/Cart";
import Step1 from "components/CheckoutView/Step1";
import Step2 from "components/CheckoutView/Step2";

import styles from "./styles.scss";

const CheckoutView = (): React$Node => {
  const t = useTranslate();
  const gaContext = useContext(AnalyticsContext);
  const sendMessage = useSendMessage();
  const history = useHistory();
  const storeInfo = useContext(StoreInfoContext);
  const quoteData = useData(QuoteData);
  const [open, setOpen] = useState(true);
  const [visited, setVisited] = useState([]);
  const client = useClient();
  const quoteState = useData(QuoteData);
  const quote = getQuoteData(quoteState);
  const { currentStep } = useCheckoutSteps();
  const step = currentStep.key;

  useEffect(() => {
    setVisited([...visited, step]);
  }, []);

  useEffect(() => {
    if (step === 0) {
      return;
    }

    if (step === 1) {
      if (quote) {
        gaContext.registerBeginCheckoutProcess(
          quote.items
        );
      }
    }

    gaContext.registerCheckoutStep(
      step,
      currentStep.id
    );
  }, [step]);

  // Set Stripe as payment method if grandTotal > 0
  useEffect(() => {
    if (quote && quote.grandTotal.incVat > 0) {
      const { payment } = quote;
      const paymentCode = payment && payment.code;
      const stripeKey = payment && payment.code === STRIPE_PAYMENT_METHOD_CODE ?
        payment.publishableKey :
        "";

      // Set payment method to stripe if selected
      if (paymentCode !== STRIPE_PAYMENT_METHOD_CODE && !stripeKey) {
        sendMessage(setQuotePaymentMethodStripe());
      }
    }
  }, [quote]);

  if (quoteData.state === "LOADING" || !quote) {
    return null;
  }

  if (quoteData.state === "LOADED" && quoteData.data.items.length === 0) {
    return (
      <div className={styles.block}>
        <Header />

        <Wrapper>
          <header className={styles.header}>
            <h1 className={styles.heading}>{t("CART.EMPTY")}</h1>
            <Button
              className={cn(styles.back, "link")}
              slotLeft={<ArrowIcon style={{ transform: "rotate(180deg)" }} />}
              onClick={() => history.push("/")}
            >
              {t("CHECKOUT.CONTINUE_SHOPPING")}
            </Button>
          </header>
        </Wrapper>
      </div>
    );
  }

  const totalItems = quote.items.reduce((total, item) => total + item.qty, 0);
  const stripeKey = quote.payment && quote.payment.code === STRIPE_PAYMENT_METHOD_CODE ?
    quote.payment.publishableKey :
    "";

  return (
    <div className={styles.block}>
      <Helmet
        title={currentStep ? currentStep.title : t("CHECKOUT.STEPS.CART.SHORT")}
      />

      <Header />

      <Wrapper>
        <header className={styles.header}>
          <h1 className={styles.heading}>
            {currentStep ? currentStep.titleLong : t("CHECKOUT.STEPS.CART.LONG")}{currentStep.id === "cart" ? ` (${totalItems})` : ""}
          </h1>
          <Button
            className={cn("link", styles.back)}
            slotLeft={<ArrowIcon style={{ transform: "rotate(180deg)" }} />}
            onClick={() => history.push("/")}
          >
            {t("CHECKOUT.CONTINUE_SHOPPING")}
          </Button>
        </header>

        <Switch>
          <Route
            exact
            path="/checkout/2"
            render={() => (
              <StripePaymentProvider
                client={client}
                amountToPay={quote.grandTotal.incVat}
                stripeKey={stripeKey}
                confirmStripePaymentIntentQuery={confirmStripePaymentIntent}
                createStripePaymentIntentQuery={createStripePaymentIntent}
                storeInfo={storeInfo.info}
              >
                <Step2
                  step={3}
                  open={open}
                  setOpen={setOpen}
                  quoteData={quoteData}
                />
              </StripePaymentProvider>
            )}
          />

          <Route exact path="/checkout" render={() => <Cart />} />
          <Route exact path="/checkout/1" render={() => <Step1 step={1} open={open} setOpen={setOpen} quoteData={quoteData} />} />
        </Switch>
      </Wrapper>
    </div>
  );
};

export default CheckoutView;
