/* @flow */

import type { Node as ReactNode } from "react";

import React, { useContext, useRef } from "react";
import cn from "classnames";
import { usePreserveScrollPosition } from "react-router-on-navigation";
import AppHeader from "components/AppHeader";
import AppFooter from "components/AppFooter";
import { Helmet } from "react-helmet-async";
import { StoreInfoContext } from "entrypoint/shared";
import { ViewModeData } from "data";
import { UiProvider } from "helpers/ui";
import { useHistory } from "react-router";

import styles from "./styles.scss";

type Props = {
  children: ReactNode,
};

const MATCH_BUY_AND_TRANSFER = /buy-and-transfer-points-wip\/(?!success)[^/]+\/?$/g;

const App = ({ children }: Props): React$Node => {
  const container = useRef(null);
  const history = useHistory();
  const onCheckout = history.location.pathname.includes("checkout") && history.location.pathname !== "/checkout/success";
  const onCountry = history.location.pathname.includes("account/country");
  const onPurchasePoints = history.location.pathname.match(MATCH_BUY_AND_TRANSFER);
  const { info } = useContext(StoreInfoContext);

  usePreserveScrollPosition(history);

  return (
    <ViewModeData.Provider>
      <UiProvider>
        <div
          ref={container} className={cn(
            styles.block,
            { [styles.onCheckout]: onCheckout },
            { [styles.onCountry]: onCountry },
            { [styles.onPurchasePoints]: onPurchasePoints }
          )}
        >
          <Helmet
            titleTemplate={`${info.titlePrefix || ""} %s ${info.titleSuffix || ""}`}
            link={[
              { rel: "icon", type: "image/png", size: "16x16", href: "/assets/browser/favicon-16x16.png" },
              { rel: "icon", type: "image/png", size: "32x32", href: "/assets/browser/favicon-32x32.png" },
              { rel: "manifest", href: "/assets/browser/site.webmanifest" },
              { rel: "mask-icon", href: "/assets/browser/safari-pinned-tab.svg", color: "#da2911" },
            ]}
            meta={[
              { name: "msapplication-TileColor", content: "#ffffff" },
              { name: "theme-color", content: "#ffffff" },
            ]}
            htmlAttributes={{ lang: info.locale.slice(0, 2) }}
          />
          <div className={styles.top}>
            {(!onCountry && !onPurchasePoints && !onCheckout) &&
              <AppHeader />
            }
            {(!onCheckout) &&
              <div className={cn(styles.height, { [styles.onCheckout]: onCheckout })} />
            }
            <div>
              {children}
            </div>
          </div>
          {(!onCheckout) && (
            <AppFooter className={styles.footer} />
          )}

        </div>
      </UiProvider>
    </ViewModeData.Provider>
  );
};

export default App;
