/* @flow */

import React from "react";
import styles from "./styles.scss";
import { useTranslate } from "@crossroads/use-translate";
import Field from "components/Field";

type Props = {
  type: "billing" | "shipping",
  className?: string,
};

const ContactInfo = ({ type, className }: Props): React$Node => {
  const t = useTranslate();

  return (
    <div className={className}>
      <div className={styles.row}>
        {type === "billing" &&
          <div className={styles.s100}>
            <Field name="email" label={t("ADDRESS.EMAIL")} variant="float" />
          </div>
        }
        <div className={styles.s100}>
          <Field name={`${type}.telephone`} label={t("ADDRESS.TELEPHONE")} variant="float" />
        </div>
      </div>
    </div>
  );
};

export default ContactInfo;
