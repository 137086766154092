/* @flow */

import type { BreadcrumbLink } from "@crossroads/ui-components";

import React from "react";
import cn from "classnames";
import { useTranslate } from "@crossroads/use-translate";
import { Breadcrumbs } from "@crossroads/ui-components";
import Wrapper from "components/Wrapper";
import styles from "./styles.scss";

type Props = {
  images?: {
    small?: {
      x1: string,
      x2: string,
    },
    medium?: {
      x1: string,
      x2: string,
    },
    large?: {
      x1: string,
      x2: string,
    },
  },
  totalCount: number,
  className?: string,
  breadcrumbLinks: $ReadOnlyArray<BreadcrumbLink>,
  breadcrumbCurrent: string,
  short?: boolean,
};

type HintProps = {
  title?: string,
  className?: string,
  breadcrumbLinks: $ReadOnlyArray<BreadcrumbLink>,
  breadcrumbCurrent: string,
};

const Delimeter = (): React$Node => <span className={styles.delimeter}>/</span>;

const ProductListHero = ({
  totalCount,
  images,
  className,
  breadcrumbLinks,
  breadcrumbCurrent,
  short = false,
}: Props): React$Node => {
  const t = useTranslate();
  const { small: smallImage, medium: mediumImage, large: largeImage } = images || {};
  const gotImage = smallImage || mediumImage || largeImage || false;

  return (
    <div
      className={cn(
        styles.block,
        className,
        { [styles.gotImage]: gotImage },
        { [styles.short]: short }
      )}
    >

      <div className={styles.innerBlock}>

        <Wrapper className={styles.wrapper}>
          <div className={styles.content}>
            <Breadcrumbs
              className={styles.breadcrumbs}
              links={breadcrumbLinks}
              Delimeter={Delimeter}
              current={breadcrumbCurrent}
            />

            <div className={styles.text}>
              {totalCount < 1 && <p className={styles.totalCount} dangerouslySetInnerHTML={{ __html: t("CATEGORY.PRODUCTS_IN_CATEGORY_TITLE_EMPTY") }} />}
              {totalCount === 1 && <p className={styles.totalCount} dangerouslySetInnerHTML={{ __html: t("CATEGORY.PRODUCTS_IN_CATEGORY_TITLE_SINGULAR", { title: breadcrumbCurrent }) }} />}
              {totalCount > 1 && <p className={styles.totalCount} dangerouslySetInnerHTML={{ __html: t("CATEGORY.PRODUCTS_IN_CATEGORY_TITLE_PLURAL", { itemsCount: totalCount, title: breadcrumbCurrent }) }} />}
            </div>
          </div>
        </Wrapper>
      </div>
    </div>
  );
};

export const ProductListHeroHint = ({
  title,
  className,
  breadcrumbLinks,
  breadcrumbCurrent,
}: HintProps): React$Node => {
  return (
    <div className={cn(styles.blockHint, className)}>
      <Wrapper className={styles.wrapper}>
        <div className={styles.content}>
          <Breadcrumbs
            className={styles.breadcrumbs}
            links={breadcrumbLinks}
            Delimeter={Delimeter}
            current={breadcrumbCurrent}
            variant="white"
          />
          <div className={styles.text}>
            {title &&
              <h1 className={styles.title}>{title}</h1>
            }
            <p className={styles.description} />
          </div>
        </div>

        <div className={styles.right} />
      </Wrapper>
    </div>
  );
};

export default ProductListHero;
