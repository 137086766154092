/* @flow */

import type { Category, HintCategory } from "shop-state/types";

import React from "react";
import { Helmet } from "react-helmet-async";
import { getCategoryMeta } from "helpers/get-meta";
import ProductListHero, { ProductListHeroHint } from "components/ProductListHero";
import FilterableProductList, { FilterableProductListHint } from "components/FilterableProductList";
import AdventCalendarHero from "components/AdventCalendarView/Hero";
import { useData, useSendMessage } from "crustate/react";
import { RouteData } from "data";
import { load as loadRoute } from "@crossroads/shop-state/route";
import { useTranslate } from "@crossroads/use-translate";
import useBreadcrumbLinks from "helpers/use-breadcrumb-links";
import { useLocation } from "react-router-dom";
import styles from "./styles.scss";

type CategoryImages = {
  +clsCatfieldsGeneralImgL: ?{ x1: string, x2: string },
  +clsCatfieldsGeneralImgM: ?{ x1: string, x2: string },
  +clsCatfieldsGeneralImgS: ?{ x1: string, x2: string },
};

type CategoryProps = {
  category: Category,
};

type HintCategoryProps = {
  category: HintCategory,
};

const getHeroImages = (category: CategoryImages) => {
  const images = {};

  if (category.clsCatfieldsGeneralImgS) {
    images.small = {
      x1: category.clsCatfieldsGeneralImgS.x1,
      x2: category.clsCatfieldsGeneralImgS.x2,
    };
  }

  if (category.clsCatfieldsGeneralImgM) {
    images.medium = {
      x1: category.clsCatfieldsGeneralImgM.x1,
      x2: category.clsCatfieldsGeneralImgM.x2,
    };
  }

  if (category.clsCatfieldsGeneralImgL) {
    images.large = {
      x1: category.clsCatfieldsGeneralImgL.x1,
      x2: category.clsCatfieldsGeneralImgL.x2,
    };
  }

  return images;
};

const CategoryView = ({ category }: CategoryProps): React$Node => {
  const meta = getCategoryMeta(category);
  const route = useData(RouteData);
  const sendMessage = useSendMessage();
  const t = useTranslate();
  const updating = route.state === "UPDATING";
  const load = location => sendMessage(loadRoute(location, true));
  const { pathname, search } = useLocation();
  const breadcrumbLinks = useBreadcrumbLinks({ current: category.name });
  const heroImages = getHeroImages(category);
  const onAdventCalendar = pathname.includes(t("ADVENT_CALENDAR.CATEGORY_URL"));

  return (
    <div className={styles.block}>
      <Helmet
        title={meta.title}
        meta={meta.data}
      />

      {onAdventCalendar ?
        <AdventCalendarHero category={category} /> :
        <ProductListHero
          title={category.heroHeading ?? ""}
          subTitle={category.heroSubHeading ?? ""}
          description={category.heroDescription ?? ""}
          totalCount={category.products.totalCount}
          images={heroImages}
          breadcrumbLinks={breadcrumbLinks}
          breadcrumbCurrent={category.name}
          short={category.heroModifier === "short"}
        />
      }

      <FilterableProductList
        updating={updating}
        productList={category.products}
        load={load}
        breadcrumbLinks={[...breadcrumbLinks, {
          pathname,
          search,
          title: category.name,
          hint: {
            name: category.name,
          },
        }]}
      />
    </div>
  );
};

export const HintCategoryView = ({ category }: HintCategoryProps): React$Node => {
  const breadcrumbLinks = useBreadcrumbLinks({ current: category.name });

  return (
    <div className={styles.block}>
      <Helmet title={category.name} />
      <ProductListHeroHint
        breadcrumbLinks={breadcrumbLinks}
        breadcrumbCurrent={category.name}
      />
      <FilterableProductListHint />
    </div>
  );
};

export default CategoryView;
