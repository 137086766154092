/* @flow */

import type { Category } from "shop-state/types";

import React from "react";
import { Link } from "react-router-dom";
import { useTranslate } from "@crossroads/use-translate";
import Button from "components/Button";

import styles from "./styles.scss";

const AdventCalendarHero = ({
  category,
}: { category: Category | null }): React$Node => {
  const t = useTranslate();

  return (
    <>
      <div className={styles.hero} />
      <div className={styles.heroContent}>
        {category !== null ?
          <>
            <h1>{ category.name }</h1>
            <p>{ category.description }</p>
            <Link
              to={`/${t("ADVENT_CALENDAR.CATEGORY_URL")}`}
            >
              <Button variant="primary">
                {t("ADVENT_CALENDAR.BACK")}
              </Button>
            </Link>
          </> :
          <>
            <h1>Julkalender 2022</h1>
            <p>Spännande erbjudanden varje dag fram till julafton.</p>
          </>
        }
      </div>
    </>
  );
};

export default AdventCalendarHero;
