module.exports = {
  host: "localhost",
  port: 9138,
  apiHost: {
    server: "http://127.0.0.1",
    client: "",
    headers: ["SSL", "Ssl-Offload", "X-Forward-Proto", "Host", "X-Real-Ip", "X-Forwarded-For", "Cookie"],
  },
  googleAnalytics: [
    { currencyCode: "SEK", accounts: ["UA-128549695-14"] }
  ],
  isProduction: true,
  proxyHost: null
};
