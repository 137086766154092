/* @flow */

import type { AdventCalendarDoorToday } from "state/advent-calendar";

import React from "react";
import cn from "classnames";
import { useData } from "crustate/react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { useTranslate } from "@crossroads/use-translate";
import { AdventCalendarData } from "data";
import Wrapper from "components/Wrapper";
import Button from "components/Button";
import LoadingView from "components/LoadingView";
import AdventCalendarHero from "components/AdventCalendarView/Hero";
import { AdventCalendarDoors } from "@crossroads/ui-components";
import styles from "./styles.scss";

const Closed = ({ text }: { text: string }) => <span>{text}</span>;

const Today = ({
  door: { day, category },
}: { door: AdventCalendarDoorToday }) => {
  const t = useTranslate();
  const {
    url, name, description, bannerSmall, bannerMedium, bannerLarge, image,
  } = category;

  return (
    <div className={styles.today}>
      <Wrapper>
        <div className={styles.todayInner}>
          <div className={styles.todayBanner}>
            <div className={styles.todaySmallBanner} style={{ backgroundImage: `url(${bannerSmall?.x2 || ""})` }} />
            <div className={styles.todayMediumBanner} style={{ backgroundImage: `url(${bannerMedium?.x2 || ""})` }} />
            <div className={styles.todayLargeBanner} style={{ backgroundImage: `url(${bannerLarge?.x2 || ""})` }} />
          </div>
          <div className={styles.todayContainer}>
            <div className={styles.todayContent}>
              <h3>{t("ADVENT_CALENDAR.DOOR_TITLE", { day })}</h3>
              <h2>{name}</h2>
              <p>{description}</p>
              <Link
                to={{
                  pathname: url,
                  state: { hint: { type: "category", category: { name, url, image, description } } },
                }}
                className={styles.todayViewAll}
              >
                <Button variant="ghost">
                  {t("ADVENT_CALENDAR.VIEW_ALL_PRODUCTS")}
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </Wrapper>
    </div>
  );
};

const AdventCalendarView = (): React$Node => {
  const t = useTranslate();
  const data = useData(AdventCalendarData);

  if (data.state !== "LOADED") {
    return <LoadingView />;
  }

  let today = null;
  data.data.forEach(day => {
    if (day.__typename === "AdventCalendarDoorToday") {
      today = day;
    }
  });

  const openDays = [];
  data.data.forEach(day => {
    if (day.__typename !== "AdventCalendarDoorLocked") {
      openDays.push(day);
    }
  });

  if (openDays.length === 0) {
    return (
      <div className={cn(styles.landing, styles.fullSize)}>
        <Wrapper>
          <div className={styles.landingContainer}>
            <h1>{t("ADVENT_CALENDAR.LANDING.HEADING")}</h1>
            <p>{t("ADVENT_CALENDAR.LANDING.TEXT")}</p>
          </div>
        </Wrapper>
      </div>
    );
  }

  return (
    <div className={styles.block}>
      <Helmet title={t("ADVENT_CALENDAR.TEXT")} />

      <AdventCalendarHero category={null} />

      {(openDays.length > 0 && today) &&
        <Today door={today} />
      }

      <Wrapper>
        <AdventCalendarDoors
          closedSlot={<Closed text={t("ADVENT_CALENDAR.CALENDAR_CLOSED")} />}
          doors={data.data}
          buttonVariant="negative" />
      </Wrapper>
    </div>
  );
};

export default AdventCalendarView;
