/* @flow */

import React, { useRef, useEffect, useState } from "react";
import cn from "classnames";

import { useTranslate } from "@crossroads/use-translate";
import { QuoteData } from "data";
import { useData } from "crustate/react";
import Button from "components/Button";
import useFormat from "helpers/use-format";

import styles from "./styles.scss";

type Props = {
  className?: string,
};

const CartMini = ({ className }: Props): React$Node => {
  const refInnerBody = useRef<?HTMLDivElement>();
  const refBody = useRef<?HTMLDivElement>();
  const [bottomAligned, setBottomAligned] = useState(false);
  const [topAligned, setTopAligned] = useState(false);
  const t = useTranslate();
  const { formatPrice } = useFormat();
  const quoteData = useData(QuoteData);
  const quote = quoteData.data ? quoteData.data : null;

  const cartItemHidden = () => {
    const refBodyRect = refBody.current && refBody.current.getBoundingClientRect();
    const refInnerBodyRect = refInnerBody.current && refInnerBody.current.getBoundingClientRect();

    if (refBodyRect && refInnerBodyRect) {
      setTopAligned(refBodyRect.top !== 0 && refBodyRect.top <= refInnerBodyRect.top);
      setBottomAligned(refBodyRect.top !== 0 && refBodyRect.bottom >= refInnerBodyRect.bottom - 5);
    }
  };

  useEffect(() => {
    const ref = refBody;
    if (ref.current) {
      ref.current.addEventListener("scroll", cartItemHidden, { passive: true });
    }

    cartItemHidden();

    return () => {
      if (ref.current) {
        ref.current.removeEventListener("scroll", cartItemHidden);
      }
    };
  }, [refBody]);

  if (!quote || quote.items.length === 0) {
    return null;
  }

  const { items, shipping, isVirtual } = quote;

  return (
    <div className={cn(styles.block, className)}>

      <div className={styles.list_container}>
        <div
          className={cn(
            styles.shadow_top,
            { [styles.shadow__hidden]: topAligned }
          )} />

        <div ref={refBody} className={styles.list}>
          <div ref={refInnerBody}>
            {items.map(x => {
              const product = x.configOption ? {
                ...x.product,
                ...x.configOption.product,
              } : x.product;

              return (
                <div key={x.itemBuyRequest} className={styles.item}>
                  <div className={styles.itemBody}>
                    <img className={styles.itemImg} src={product.attributes.image?.x1} />
                    <div className={styles.itemTextContainer}>
                      <p className={styles.itemName}>
                        {product.name}
                      </p>
                      <p className={styles.itemBrand}>
                        {product.attributes.manufacturer}
                      </p>
                      <p className={styles.itemQty}>
                        {x.qty} x {formatPrice(x.product.price.incVat)}
                      </p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div
          className={cn(
            styles.shadow_bottom,
            { [styles.shadow__hidden]: bottomAligned }
          )} />
      </div>

      <div className={styles.totals}>
        <div className={styles.totalsRow}>
          <span>{t("CART.SUBTOTAL")}</span>
          <span>
            {formatPrice(quote.subTotal.incVat)}
          </span>
        </div>

        {(!isVirtual &&
          shipping &&
          shipping.method) &&
          <div className={styles.totalsRow}>
            <span>{t("CART.SHIPPING")}</span>
            <span> {formatPrice(shipping.total.incVat)}</span>
          </div>
        }

        <div className={cn(styles.totalsRow, styles.totalsRow__grandtotal)}>
          <strong>{t("CART.GRANDTOTAL")}</strong>
          <div>
            <strong>
              {formatPrice(quote.grandTotal.incVat)}
            </strong>
          </div>
        </div>
      </div>

      <div className={styles.bottom}>
        <Button disabled={items.length === 0} to="/checkout/1" variant="primary">{t("CART.CTA")}</Button>
        <Button disabled={items.length === 0} to="/checkout" variant="ghost">{t("CART.EDIT")}</Button>
      </div>
    </div>
  );
};

export default CartMini;
