/* @flow */

import type { QuoteAddress } from "shop-state/types";
import type { QuoteResponse } from "@crossroads/shop-state/quote";

import React, { useEffect, useState, useContext } from "react";
import cn from "classnames";
import styles from "./styles.scss";
import { Link, useHistory } from "react-router-dom";
import { useClient } from "entrypoint/shared";
import { QUOTE_PLACE_ORDER_RESPONSE, syncQuote } from "@crossroads/shop-state/quote";
import { StripeContext, STRIPE_PAYMENT_METHOD_CODE, useInitStripeQuotePaymentMethod } from "@crossroads/stripe";
import { Form, rules, isRequired } from "@awardit/formaggio";
import { getQuoteData } from "state/quote";
import { useTranslate } from "@crossroads/use-translate";
import useFormat from "helpers/use-format";
import { QuoteData } from "data";
import { useSendMessage, useData } from "crustate/react";
import { addMessage, addMessageTranslated } from "@crossroads/shop-state/messages";
import Container from "components/CheckoutView/Container";
import CustomerServiceLink from "components/CheckoutView/CustomerServiceLink";
import { Foldable } from "@crossroads/ui-components";
import PaymentMethods from "components/PaymentMethods";
import ChevronIcon from "icons/chevron-big.svg";
import CartSummary from "components/CartSummary";
import DiscountCode from "components/CheckoutView/DiscountCode";
import {
  placeOrder,
  quote as quoteQuery,
} from "queries";

type Props = {
  open: boolean,
  setOpen: boolean => void,
};

const validation = rules([
  isRequired("terms"),
]);

const useFormatAddress = () => {
  const t = useTranslate();

  return (addresses: $ReadOnlyArray<QuoteAddress>) => {
    let billingUsedAsShipping = false;

    for (const a of addresses) {
      if (a.type === "billing" && a.isUsedAsShipping) {
        billingUsedAsShipping = true;
        break;
      }
    }

    const a = addresses.find(x => x.type === (billingUsedAsShipping ? "billing" : "shipping"));

    if (a) {
      const missingAddressField = Object.values(a).some(x => x === null || x === "");

      if (!missingAddressField) {
        return `${a.firstname} ${a.lastname}, ${a.street[0]}, ${a.postcode} ${a.city}`;
      }
    }

    return (<span className={styles.emptyAddress}>{t("CHECKOUT.ADDRESS.EMPTY_ADDRESS")}</span>);
  };
};

const Step2 = ({ open, setOpen }: Props): React$Node => {
  const t = useTranslate();
  const formatAddress = useFormatAddress();
  const client = useClient();
  const { push } = useHistory();
  const sendMessage = useSendMessage();
  const { formatPrice } = useFormat();
  const quoteData = useData(QuoteData);
  const [state, setState] = useState({ terms: false });
  const [processing, setProcessing] = useState(false);
  const { stripe, stripePaymentReq, payWithStripe } = useContext(StripeContext);
  const quote = getQuoteData(quoteData);
  const formErrors = Array.isArray(quote?.validationErrors) && quote.validationErrors.length > 0;
  const errors = validation(state);
  const [openAccordion, setOpenAccordion] = useState("");

  useInitStripeQuotePaymentMethod(quoteData.data || null);

  const handleResize = () => {
    setOpenAccordion("");
  };

  // Open summary when this route renders
  useEffect(() => {
    setOpen(true);
  }, [setOpen]);

  useEffect(() => {
    if (process.browser) {
      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }
  }, []);

  if (!quoteData.data) {
    return null;
  }

  const { items, payment, addresses } = quoteData.data;
  const addressLabel = formatAddress(addresses);

  const submit = async (e: SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!payment) {
      return;
    }

    setProcessing(true);

    try {
      if (payment.code === STRIPE_PAYMENT_METHOD_CODE) {
        await payWithStripe();
      }

      const placeOrderData = await client(placeOrder);
      const newQuoteData = await client(quoteQuery);
      const newQuote = newQuoteData.quote;

      if (placeOrderData && placeOrderData.placeOrder.result !== "success") {
        sendMessage(addMessage(placeOrderData.placeOrder.result, "error"));

        // Sync quote and return
        if (newQuote) {
          sendMessage(syncQuote(newQuote));
        }

        return;
      }

      sendMessage(syncQuote(newQuote));

      if (newQuote) {
        sendMessage(({
          tag: QUOTE_PLACE_ORDER_RESPONSE,
          data: newQuote,
        }: QuoteResponse));
      }

      push("/checkout/success");
    }
    catch (e_) {
      sendMessage(addMessageTranslated(e_.message, "error"));
    }
    finally {
      setProcessing(false);
    }
  };

  return (
    <Container
      right={
        <div>
          <CartSummary
            open={open}
            setOpen={setOpen}
            allowBackNavigation={false}
          >
            <Form
              value={(state: any)}
              errors={errors}
              onSubmit={submit}
              onChange={(x: any) => {
                setState({ ...state, ...x });
              }}
            >

              <div className={styles.paymentMethods}>
                <PaymentMethods
                  terms={state.terms}
                  disabled={formErrors}
                  paymentMethodCode={payment?.code || ""}
                  loading={!stripe || !stripePaymentReq}
                  processing={processing} />
              </div>
            </Form>

          </CartSummary>
          <CustomerServiceLink />
        </div>
      }
    >

      <div className={styles.accordion}>
        <header
          className={styles.accordionHeader}
          onClick={() => setOpenAccordion(openAccordion === "products" ? "" : "products")}
        >
          <div>
            <p className={styles.title}>
              {t("CHECKOUT.ACCORDION_HEADER_CART")}
              <span>
              &nbsp;({items.length})
              </span>
            </p>
          </div>
          <ChevronIcon
            className={cn(styles.closeIcon, { [styles.open]: openAccordion === "products" })}
          />
        </header>
        <Foldable open={openAccordion === "products"}>
          <div className={styles.items}>
            {items.map(x => (
              <div key={x.itemBuyRequest} className={styles.item}>
                <div className={styles.left}>
                  <img
                    className={styles.image}
                    alt={x.product.name}
                    src={x.product.attributes.image?.x1} />
                  <div className={styles.info}>
                    <div>
                      <p className={styles.name}>{x.product.name}</p>
                      <p className={styles.brand}>{x.product.attributes.manufacturer}</p>
                    </div>
                    <span className={styles.price}>
                      {formatPrice(x.rowTotal.incVat)}
                    </span>
                  </div>
                </div>

                <div className={styles.right}>
                  <Link className={styles.link} to="/checkout">
                    {t("CHECKOUT.EDIT")}
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </Foldable>
      </div>

      <h3 className={styles.heading}>{t("CHECKOUT.PAYMENT_AND_DELIVERY")}</h3>

      <div className={styles.summary}>
        <div className={styles.summaryRow}>
          <div className={styles.left}>
            <div className={styles.info}>
              <div>
                <p className={styles.title}>{t("CHECKOUT.SENT_TO")}</p>
                <p className={styles.subTitle}>
                  {addressLabel}
                </p>
              </div>
            </div>
          </div>
          <div className={styles.right}>
            <Link className={styles.link} to="/checkout/1">
              {t("CHECKOUT.EDIT")}
            </Link>
          </div>
        </div>
      </div>

      <h3 className={styles.heading}>{t("CHECKOUT.DISCOUNT_CODE.HEADER")}</h3>

      <DiscountCode
        disabled={processing}
        coupon={quote?.coupon}
        setSummaryOpen={setOpen}
      />
    </Container>
  );
};

export default Step2;
