/* @flow */

import React from "react";
import styles from "./styles.scss";
import { useTranslate } from "@crossroads/use-translate";
import Button from "components/Button";
import Wrapper from "components/Wrapper";
import ChevronIcon from "icons/chevron.svg";
import Splash from "icons/okq8splash.svg";

type Props = {
  smallImage: { x1: string, x2: string },
  largeImage: { x1: string, x2: string },
  heading: string,
  link: string,
  CTA: string,
  modifier: string,
};

export const pixelSrc = "data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==";

const Hero = ({
  smallImage,
  largeImage,
  heading,
  CTA,
  link,
  modifier }: Props): React$Node => {
  const t = useTranslate();

  const images = [
    {
      media: "(max-width: 550px)",
      url: smallImage ? `${smallImage.x1} 1x, ${smallImage.x2} 2x` : null,
      dataUrl: pixelSrc,
    },
    {
      media: "(min-width: 551px)",
      url: largeImage ? `${largeImage.x1} 1x, ${largeImage.x2} 2x` : null,
      dataUrl: pixelSrc,
    },
  ];

  return (
    <div className={styles.block} style={{ backgroundColor: modifier }}>
      <Wrapper className={styles.wrapper}>
        <div className={styles.contentBlock}>
          <Splash className={styles.splash} />
          {heading ? <h1 className={styles.heading}>{heading}</h1> : null}
          {link && CTA ?
            <Button
              elem="a"
              variant="arrow"
              size="small"
              className={styles.cta}
              href={link}
              slotLeft={<ChevronIcon />}
            >
              {CTA}
            </Button> :
            null}
        </div>
      </Wrapper>
      <picture className={styles.image} alt={t("HOMEVIEW.HERO_ALT")}>

        {images.map(({ url, media }) => (
          <source
            key={url}
            srcSet={url}
            media={media}
          />
        ))}
        {largeImage && (
          <img
            alt={t("HOMEVIEW.HERO_ALT")}
            draggable="false"
            src={largeImage.x1}
            data-object-fit="cover"
          />
        )}
      </picture>
    </div>
  );
};

export default Hero;
