/* @flow */

import type { Quote } from "shop-state/types";

import type { Model } from "crustate";
import type {
  Data as QuoteData,
  QuoteRequest as Request,
  QuoteResponse as Response } from "@crossroads/shop-state/quote";

import { updateData, updateNone } from "crustate";
import { QuoteModel as BaseModel } from "@crossroads/shop-state/quote";

type SetDiscountCodeRequest = {
  +tag: typeof QUOTE_SET_DISCOUNT_CODE_REQUEST,
  +code: string,
};

type RemoveDiscountCodeRequest = {
  +tag: typeof QUOTE_REMOVE_DISCOUNT_CODE_REQUEST,
};

export type QuoteRequest = Request | SetDiscountCodeRequest | RemoveDiscountCodeRequest;

export type QuoteResponse = Response | {
  +tag: typeof QUOTE_SET_DISCOUNT_CODE_RESPONSE,
  +data: Quote,
} | {
  +tag: typeof QUOTE_REMOVE_DISCOUNT_CODE_RESPONSE,
  +data: Quote,
};

export type Data = QuoteData
  | {| +state: typeof QUOTE_SETTING_DISCOUNT_CODE, +data: Quote |}
  | {| +state: typeof QUOTE_REMOVING_DISCOUNT_CODE, +data: Quote |};

export const QUOTE_SET_DISCOUNT_CODE_REQUEST: "quote/set_discount_code/request" = "quote/set_discount_code/request";
export const QUOTE_SET_DISCOUNT_CODE_RESPONSE: "quote/set_discount_code/response" = "quote/set_discount_code/response";

export const QUOTE_REMOVE_DISCOUNT_CODE_REQUEST: "quote/remove_discount_code/request" = "quote/remove_discount_code/request";
export const QUOTE_REMOVE_DISCOUNT_CODE_RESPONSE: "quote/remove_discount_code/response" = "quote/remove_discount_code/response";

export const QUOTE_SETTING_DISCOUNT_CODE: "SETTING_DISCOUNT_CODE" = "SETTING_DISCOUNT_CODE";
export const QUOTE_REMOVING_DISCOUNT_CODE: "REMOVING_DISCOUNT_CODE" = "REMOVING_DISCOUNT_CODE";

export const setQuoteDiscountCode = (code: string): SetDiscountCodeRequest => ({
  tag: QUOTE_SET_DISCOUNT_CODE_REQUEST,
  code,
});

export const removeQuoteDiscountCode = (): RemoveDiscountCodeRequest => ({
  tag: QUOTE_REMOVE_DISCOUNT_CODE_REQUEST,
});

export const QuoteModel: Model<Data, {}, QuoteRequest | QuoteResponse> = {
  id: "quote",
  init: BaseModel.init,
  update: (state, msg) => {
    switch (msg.tag) {
      case QUOTE_SET_DISCOUNT_CODE_REQUEST:
        if (state.state === "LOADED") {
          return updateData({
            state: QUOTE_SETTING_DISCOUNT_CODE,
            data: state.data,
          }, msg);
        }

        console.error("Invalid state transition");
        return updateNone();

      case QUOTE_REMOVE_DISCOUNT_CODE_REQUEST:
        if (state.state === "LOADED") {
          return updateData({
            state: QUOTE_REMOVING_DISCOUNT_CODE,
            data: state.data,
          }, msg);
        }

        console.error("Invalid state transition");
        return updateNone();

      case QUOTE_SET_DISCOUNT_CODE_RESPONSE:
        if (state.state === QUOTE_SETTING_DISCOUNT_CODE) {
          return updateData({ state: "LOADED", data: msg.data });
        }

        console.error("Invalid state transition");
        return updateNone();

      case QUOTE_REMOVE_DISCOUNT_CODE_RESPONSE:
        if (state.state === QUOTE_REMOVING_DISCOUNT_CODE) {
          return updateData({ state: "LOADED", data: msg.data });
        }

        console.error("Invalid state transition");
        return updateNone();
      default:
        if (state.state !== QUOTE_SETTING_DISCOUNT_CODE &&
          state.state !== QUOTE_REMOVING_DISCOUNT_CODE) {
          return BaseModel.update(state, msg);
        }
    }
  },
};

export const getQuoteData = (d: Data): ?Quote => {
  return d.data || null;
};
