/* @flow */

import type {
  CmsPage,
} from "shop-state/types";

import type { Model, EffectErrorMessage } from "crustate";
import type { Response } from "./util";
import { updateData, EFFECT_ERROR } from "crustate";

type Data =
  | { state: "INIT" }
  | { state: "LOADING" }
  | { state: "LOADED", data: CmsPage };

export type TermsResponse =
  Response<typeof TERMS_RESPONSE, ?CmsPage> | EffectErrorMessage;

export type TermsRequest = {
  tag: typeof TERMS_REQUEST,
  url: string,
};

export const TERMS_RESPONSE: "response/terms" = "response/terms";
export const TERMS_REQUEST: "request/terms" = "request/terms";

export const load = (url: string): TermsRequest => {
  return {
    tag: TERMS_REQUEST,
    url,
  };
};

export const TermsModel: Model<Data, {
  url: string}, TermsRequest | TermsResponse> = {
    id: "terms",
    init: ({ url }) =>
      updateData(
        { state: "LOADING" },
        load(url)
      ),
    update: (state: Data, msg) => {
      switch (msg.tag) {
        case EFFECT_ERROR:
          throw new Error("Route load failed");
        case TERMS_RESPONSE:
          if (msg.data && (state.state === "LOADING")) {
            return updateData({ state: "LOADED", data: msg.data });
          }

          break;
        default:
      }
    },
  };
