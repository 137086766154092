/* @flow */

import type { ProductCardProduct } from "shop-state/types";
import type { BreadcrumbLink } from "@crossroads/ui-components";

import React, { useRef, useContext, useEffect, useState, useMemo } from "react";
import cn from "classnames";
import { useTranslate } from "@crossroads/use-translate";
import { AnalyticsContext, ProductLink } from "@crossroads/analytics";
import { Square, ProductListMedia } from "@crossroads/ui-components";
import useFormat from "helpers/use-format";
import { getLowestMSRP } from "helpers/utils";
import { formatConfigColors } from "components/ProductOptions/color";

import styles from "./styles.scss";

type ProductCardProps = {
  product: ProductCardProduct,
  className?: string,
  list?: string,
  position?: number,
  breadcrumbLinks?: $ReadOnlyArray<BreadcrumbLink>,
};

type DiscountTagProps = {
  msrp: ?number,
  price: number,
};

type PriceProps = {
  msrp: ?number,
  price: number,
  showDiscount: ?boolean,
};

const DiscountTag = ({ msrp, price }: DiscountTagProps): React$Node => {
  const t = useTranslate();

  const discount = typeof msrp === "number" ?
    Math.floor((1 - (price / msrp)) * 100) : 0;

  return (
    discount > 5 ?
      <p className={styles.discount}>
        {t("PRODUCT.DISCOUNT_SHORT", { discount })}
      </p> : null
  );
};

const Price = ({ msrp, price, showDiscount }: PriceProps) => {
  const { formatPrice } = useFormat();
  const showMSRP = typeof msrp === "number" && msrp > price;

  return (
    <div className={styles.priceContainer}>
      <p className={cn(styles.price, { [styles.showMSRP]: showMSRP })}>
        {formatPrice(price)}
        &nbsp;
        {typeof msrp === "number" && <span>{formatPrice(msrp)}</span>}
      </p>
      {showDiscount === true &&
        <DiscountTag price={price} msrp={msrp} showDiscount={showDiscount} />
      }
    </div>
  );
};

const ProductCard = ({
  product,
  className,
  breadcrumbLinks,
  position,
  list,
}: ProductCardProps): React$Node => {
  const gaContext = useContext(AnalyticsContext);
  const gaRef = useRef();
  // Default to smallest image
  const [imageSrc, setImageSrc] = useState<string>("");
  const options = product.type === "configurable" ? product.options.items : [];
  const msrp = useMemo(() => getLowestMSRP(product), [product]);
  const price = product.price.incVat;

  useEffect(() => {
    if (!gaRef.current || !list) {
      return;
    }

    gaContext.register(gaRef.current, {
      id: product.sku,
      name: product.name,
      brand: product.attributes.manufacturer,
      price: product.price.exVat,
      category: list,
      list_position: Number(position) + 1, // eslint-disable-line camelcase
      list_name: list, // eslint-disable-line camelcase
    });
  }, [gaRef]);

  return (
    <ProductLink
      className={cn(styles.block, className)}
      to={{
        pathname: product.url,
        state: { hint: {
          type: "product",
          product,
          image: imageSrc,
        },
        breadcrumbLinks },
      }}
      product={{
        name: product.name,
        sku: product.sku,
        price: product.price,
        attributes: {
          manufacturer: product.attributes.manufacturer,
        },
      }}
    >
      <div className={styles.colors}>
        {options
          .filter(x => Boolean(x.product.attrLabels.color))
          .map((x, i) => (
            <div
              key={i}
              style={{ background: `linear-gradient(${formatConfigColors(x.product.attrLabels.color?.title)})` }} />
          ))}
      </div>

      <div className={styles.header}>
        <ProductListMedia
          className={styles.imageWrapper}
          product={product}
          getCurrentImageSrc={x => setImageSrc(x)}
        />
      </div>

      <div className={styles.body}>
        <div>
          <span className={styles.name}>{product.name}</span>
          <p className={styles.brand}>{product.attributes.manufacturer}</p>
        </div>
        <Price price={price} msrp={msrp} showDiscount={product.attributes.showDiscount} />
      </div>

    </ProductLink>
  );
};

export const DummyCard = ({ className }: { className?: string }): React$Node => {
  return (
    <div className={cn(className, styles.block, styles.dummy)}>
      <div className={styles.imageWrapper}>
        <div className={styles.image}>
          <Square />
        </div>
      </div>

      <div className={styles.body}>
        <p className={styles.brand}>&nbsp;</p>
        <span className={styles.name}>&nbsp;</span>

        <div>
          <p className={styles.price}>&nbsp;</p>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
