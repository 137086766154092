/* @flow */

import type {
  SortableProductList,
  FilterableProductList as TFilterableProductList,
  FilterLocation,
  ProductSort,
} from "shop-state/types";
import type { BreadcrumbLink } from "@crossroads/ui-components";

import React from "react";
import cn from "classnames";
import { useTranslate } from "@crossroads/use-translate";
import Wrapper from "components/Wrapper";
import { useUi } from "helpers/ui";
import ProductList from "components/ProductList";
import Button from "components/Button";
import PaginatedProductList from "components/PaginatedProductList";
import { PAGE_SIZE } from "effects/route";
import {
  OffCanvasFilterMenu,
  OffCanvasSortMenu,
  Filterbar,
  StickBelowHeader,
  useFilter,
} from "@crossroads/ui-components";
import FilterIcon from "icons/filter_icon.svg";
import SortIcon from "icons/sort_icon.svg";

import styles from "./styles.scss";

type Props = {
  children?: React$Node,
  updating: boolean,
  productList: SortableProductList | TFilterableProductList,
  load: FilterLocation => any,
  breadcrumbLinks?: $ReadOnlyArray<BreadcrumbLink>,
};

type InnerProps = {
  children?: React$Node,
  updating: boolean,
  productList: SortableProductList | TFilterableProductList,
  load: FilterLocation => any,
  sortValues?: Array<ProductSort>,
};

const FilterableProductListInner = ({
  children, updating, productList, load, sortValues }: InnerProps) => {
  const t = useTranslate();
  const { filterOCMOpen, setFilterOCMOpen, sortOCMOpen, setSortOCMOpen } = useUi();
  const filterConfig = {
    loading: updating,
    productList,
    usePoints: false,
    load,
    sortValues,
  };
  const filterState = useFilter(filterConfig);
  const headerHeight = Number.parseInt(styles.headerHeight, 10);

  const FilterButton = () => (
    <Button
      className={styles.sortButton}
      size="medium"
      variant="ghost"
      slotLeft={<FilterIcon className={styles.filterIcon} />}
      onClick={() => setFilterOCMOpen(true)}
    >
      {t("FILTER.FILTERBAR.ALL_FILTERS")}
    </Button>
  );

  const SortButton = () => (
    <Button
      className={styles.sortButton}
      size="medium"
      variant="ghost"
      slotLeft={<SortIcon className={styles.sortIcon} />}
      onClick={() => setSortOCMOpen(true)}
    >
      {t("FILTER.FILTERBAR.SORT")}
    </Button>
  );

  return (
    <>
      <OffCanvasFilterMenu
        isOpen={filterOCMOpen}
        close={() => setFilterOCMOpen(false)}
        filterState={filterState}
      />

      <OffCanvasSortMenu
        isOpen={sortOCMOpen}
        close={() => setSortOCMOpen(false)}
        filterState={filterState}
        side="RIGHT"
      />

      <StickBelowHeader
        className={styles.filterbar}
        headerHeight={headerHeight}
        render={fixed => (
          <Wrapper className={styles.filterWrapper}>
            <Filterbar
              className={cn({ [styles.filterbarFixed]: fixed })}
              Button={FilterButton}
              SortButton={SortButton}
              filterState={filterState}
              openFilters={() => setFilterOCMOpen(true)}
            />
          </Wrapper>
        )}
      />

      {!filterState.visible && <div className={styles.spacer} />}

      {children}
    </>
  );
};

const BLACKLISTED_SORT_VALUES = new Set(["position"]);

const useGetSortValues = (productList: SortableProductList | TFilterableProductList) => {
  const t = useTranslate();

  if (!productList.sortableBy) {
    return [];
  }

  const sortableBy = productList.sortableBy
    .filter(sb => !BLACKLISTED_SORT_VALUES.has(sb.code));

  const sortValues = [
    {
      code: ``,
      label: t("FILTER.FILTERBAR.SORT_DEFAULT"),
    },
  ];

  for (const value of sortableBy) {
    sortValues.push({
      code: `${value.code}_asc`,
      label: `${value.label} ↑`,
    }, {
      code: `${value.code}_desc`,
      label: `${value.label} ↓`,
    });
  }

  return sortValues;
};

const FilterableProductList = ({
  children,
  updating,
  productList,
  load,
  breadcrumbLinks }: Props): React$Node => {
  const numPages = Math.ceil(productList.totalCount / PAGE_SIZE);
  const sortValues = useGetSortValues(productList);

  return (
    <FilterableProductListInner
      productList={productList}
      sortValues={sortValues}
      numPages={numPages}
      updating={updating}
      load={load}
    >
      <Wrapper>
        {children}
        <PaginatedProductList
          updating={updating}
          numPages={numPages}
          productList={productList}
          breadcrumbLinks={breadcrumbLinks}
        />
      </Wrapper>
    </FilterableProductListInner>
  );
};

export const FilterableProductListHint = ({ children }: { children?: React$Node }): React$Node => (
  <Wrapper>
    <StickBelowHeader className={styles.filterbar}>
      <div className={styles.filterBarHint} />
    </StickBelowHeader>
    {children}
    <ProductList products={[null, null, null, null, null, null, null, null]} />
  </Wrapper>
);

export default FilterableProductList;
