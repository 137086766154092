/* @flow */

import React, { useContext } from "react";
import { useData } from "crustate/react";
import { useBrowser } from "@awardit/react-use-browser";
import { Link } from "react-router-dom";
import { HomeData } from "data";
// import useDevice from "helpers/use-device";
import { StoreInfoContext } from "entrypoint/shared";
import { Helmet } from "react-helmet-async";
import { useTranslate } from "@crossroads/use-translate";
import { formatMeta } from "helpers/get-meta";
import Wrapper from "components/Wrapper";
import Hero from "components/Hero";
import LoadingView from "components/LoadingView";
import Categories from "components/HomeView/Categories";
import ProductPromotion from "./ProductPromotion";
import useBrowserDimensions from "helpers/use-browser-dimensions";
import ProductCard from "components/ProductCard";
import { useUi } from "helpers/ui";
import { MODE } from "state/view-mode";
import Button from "components/Button";

import styles from "./styles.scss";

const HomeView = (): React$Node => {
  const browser = useBrowser();
  const t = useTranslate();
  const home = useData(HomeData);
  const { info } = useContext(StoreInfoContext);
  const { width: browserWidth } = useBrowserDimensions();
  const { setViewMode } = useUi();

  if (home.state !== "LOADED") {
    return <LoadingView />;
  }

  const slideData = (Object.values(home.data): any)
    .filter(e => e && Object.prototype.hasOwnProperty.call(e, "carousel"))
    .map(c => ({
      name: c.carousel.name,
      url: c.carousel.url,
      image: c.carousel.image,
    }));

  const meta = formatMeta({
    title: info.defaultTitle,
    description: info.defaultDescription,
  });

  const getPopularProductAmount = () => {
    if (browserWidth < Number.parseInt(styles.small, 10)) {
      return 2;
    }

    if (browserWidth < Number.parseInt(styles.medium, 10)) {
      return 3;
    }

    return 4;
  };

  return (
    <div className={styles.block}>
      <Helmet
        title={meta.title}
        meta={meta.data}
      />

      <Hero
        smallImage={home.data.hero.block.smallImage}
        largeImage={home.data.hero.block.largeImage}
        modifier={home.data.hero.block.modifier}
        heading={home.data.hero.block.heading}
        subHeading={home.data.hero.block.subHeading}
        splash={home.data.hero.block.splash}
        text={home.data.hero.block.text}
        link={home.data.hero.block.link}
        CTA={home.data.hero.block.cta}
      />

      <Wrapper>

        <div className={styles.popularCategories}>
          <h2 className={styles.title}>{t("HOMEVIEW.POPULAR_CATEGORIES")}</h2>

          <Categories items={slideData} className={styles.categories} />
          <Button
            variant="blank"
            className={styles.cta}
            onClick={() => setViewMode(MODE.CATEGORIES)}
          >
            {t("HOMEVIEW.SEE_MORE")}
          </Button>
        </div>

        { home.data.featuredProducts.category.products.items.length >= 3 ?
          <div className={styles.popularProducts}>
            <h2 className={styles.title}>{t("HOMEVIEW.POPULAR_PRODUCTS")}</h2>
            <div className={styles.products}>
              {browser && home.data.featuredProducts.category.products.items
                .slice(0, getPopularProductAmount())
                .map(p => (
                  <div key={p.name} className={styles.product}>
                    <ProductCard product={p} />
                  </div>
                ))}
            </div>
            <Link
              className={styles.cta}
              to={{
                pathname: home.data.featuredProducts.category.url,
              }}
            >
              <Button variant="blank">
                {t("HOMEVIEW.SEE_MORE")}
              </Button>
            </Link>
          </div> :
          null }

        <ProductPromotion {...home.data.block2FullWidth.block} />

        <div className={styles.double}>
          <ProductPromotion {...home.data.block1Left.block} />
          <ProductPromotion {...home.data.block1Right.block} />
        </div>
      </Wrapper>
    </div>
  );
};

export default HomeView;
