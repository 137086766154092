/* @flow */

import type { Level, Content } from "@crossroads/shop-state/messages";

import React, { useState, useEffect } from "react";
import cn from "classnames";
import { useData, useSendMessage } from "crustate/react";
import { useTranslate } from "@crossroads/use-translate";
import { Foldable } from "@crossroads/ui-components";
import Wrapper from "components/Wrapper";
import { clearMessages } from "@crossroads/shop-state/messages";
import { MessagesData } from "data";
import RemoveIcon from "icons/close-big.svg";
import useBrowserLayoutEffect from "helpers/use-browser-layout-effect";

import styles from "./styles.scss";

type MessageProps = {
  content: Content,
  level: Level,
  translated: boolean,
  deleting: boolean,
  onClick: () => void,
};

const Message = ({ content, level, translated, deleting, onClick }: MessageProps): React$Node => {
  const t = useTranslate();
  const [open, setOpen] = useState(false);

  const text = typeof content === "string" ?
    t("STATUS_CODE." + content) :
    t("STATUS_CODE." + content.translationKey, content.variable);

  useEffect(() => {
    setOpen(true);
  }, []);

  return (
    <Foldable
      open={!deleting && open}
      className={cn(
        styles.message,
        styles[level]
      )}
      onClick={onClick}
    >
      <div className={styles.itemInner}>
        <div className={styles.itemPadding}>
          <Wrapper className={styles.wrapper}>
            <p>
              {translated && typeof content === "string" ? content : text}
            </p>
          </Wrapper>
          <RemoveIcon className={styles.icon} />
        </div>
      </div>
    </Foldable>
  );
};

const SystemMessages = (): React$Node => {
  const sendMessage = useSendMessage();
  const messages = useData(MessagesData);
  const [leaving, setLeaving] = useState(false);
  const [appearing, setAppearing] = useState(false);

  useBrowserLayoutEffect(() => {
    if (messages.length > 0) {
      setTimeout(() => {
        setAppearing(true);
      }, 0);
    }
    else {
      setAppearing(false);
    }
  }, [messages]);

  const closeAll = () => {
    setLeaving(true);
    setTimeout(() => {
      setLeaving(false);
      sendMessage(clearMessages());
    }, Number.parseInt(styles.transition, 10));
  };

  return (
    <div className={cn({
      [styles.appearing]: appearing,
      [styles.leaving]: leaving,
    })}
    >
      {messages.length > 0 &&
        <div
          className={styles.dim}
          onClick={closeAll}
        />
      }
      <div className={styles.block}>
        <div className={styles.messages}>
          {messages.map(x => (
            <Message
              {...x}
              key={x.id}
              deleting={leaving}
              onClick={closeAll}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default SystemMessages;
