/* @flow */

import type { FilterableProductList as TFilterableProductList } from "shop-state/types";

import React from "react";
import { Helmet } from "react-helmet-async";
import { useData, useSendMessage } from "crustate/react";
import { useTranslate } from "@crossroads/use-translate";
import { PopularData } from "data";
import FilterableProductList, { FilterableProductListHint } from "components/FilterableProductList";
import { load } from "state/popular";
import styles from "./styles.scss";

type Props = {
  productList: TFilterableProductList,
  updating: boolean,
};

const PopularViewWrapper = (): React$Node => {
  const popular = useData(PopularData);

  if (popular.state === "LOADED" || popular.state === "UPDATING") {
    return (
      <PopularView
        productList={popular.data}
        updating={popular.state === "UPDATING"}
      />
    );
  }

  return <HintPopularView />;
};

const PopularView = ({ productList, updating }: Props) => {
  const t = useTranslate();
  const sendMessage = useSendMessage();
  const loadFn = location => sendMessage(load(location, true));

  return (
    <div className={styles.block}>
      <Helmet title={t("POPULAR.POPULAR_PRODUCTS")} />

      <FilterableProductList
        updating={updating}
        productList={productList}
        load={loadFn}
      />
    </div>
  );
};

const HintPopularView = () => (
  <div className={styles.block}>
    <FilterableProductListHint />
  </div>
);

export default PopularViewWrapper;
